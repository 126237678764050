.student{
    
    position: relative;
    display: flex;
    left: 580px;

    
    
    padding-left: 20px;
    padding-right: 20px;
   
    
    font-weight: 200;
    color:#ff870e;
}
.img{
    width: 100px;
}
.ind-section{
    display: flex;
    padding: 0 100px;
    
}
.sub-ind-sec1{
      
    padding: 10px;
    width: 40%;
    margin:1rem 1rem;
    color: black;
    font-size: var(--content1);
    background-color: var(--yellow);

}
.sub-ind-sec2{
    background-color: var(--yellow);
    padding: 10px ;
    width: 100%;
    margin:1rem 1rem; 

}
.bimage img{
    position: relative;
    display: flex;
    left: 100px;
    justify-content: center;
    aspect-ratio: 4/3;
    
    height: 300px;
}
.sub-ind-sec3{
 
    background-color: var(--yellow);
    padding: 10px ;
    width: 50%;
    margin:1rem 1rem; 

    height: auto;

}
.sub-ind-sec4{
    display: flex;
    justify-content: center;
    background-color: var(--yellow);
    padding: 10px ;
    width: 50%;
    margin:1rem 1rem; 
 
  

}
.sub-ind-sec5{
    
    width: 47.6%;
    margin:1rem 1rem;
    color: black;
    font-size: var(--content1);
    border-top: 1.2px solid #005A9C;
    border-bottom: 1.2px solid #005A9C;
    border-width: 80%;
   height: 500px;




}
.sub-ind-sec6{
    
    width: 47.5%;
    margin:1rem 1rem;
    color: black;
    font-size: var(--content1);
    background-color: var(--yellow);
  



}


.stats-content img{
   height:400px;

 
   background-color:var(--yellow);


  width: 100%;

}


.sub-ind-sec9{
    background-color: var(--yellow);
    padding: 10px;
    width: 100%;
    padding: 10px; 
    margin: 1rem 1rem;    

}
.sub-ind-sec99{
 
    padding: 10px;
    width: 100%;
    padding: 10px; 
    margin: 1rem 1rem;  
    border: 0;
   
    border-top: 1.2px solid #005A9C;
    border-bottom: 1.2px solid #005A9C;
    border-bottom: 80%;

}

.ind-head2{
  font-size: var(--head1);  
  color: var(--red);
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
 
  margin: 1rem 0rem ;
}
.industry-vid{
    width: 350px;
    height: auto;
}
.national img {
    height: 100px;
    width: auto;
}


.national {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 2rem 8rem;
}

.international {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 2rem 8rem;
}

.international img {
    height: 100px;
    width: auto;
}

.industrycolab img {
    height: 80px;
    width: auto;
}

.industrycolab {
    display: flex;
   position: relative;
    justify-content: center;
    left: -50px;
    gap: 10rem;
    
}

.intrntnl-head {
    font-size: var(--head1);
    color: var(--red);
    padding: 2rem 8rem;
}

.ntnl-head {
    font-size: var(--head1);
    color: var(--red);
    padding: 2rem 8rem;
}
.footprints-logos{
    background-color: var(--yellow);
    display: flex;
    flex-wrap: wrap;
     gap: 1rem;
     justify-content: center;
}
.footprints-logos img{
    width: auto;
    height: 80px;
    padding: 10px;   
}
.stats{
    width: auto;
    height: auto;
    padding: 20px;
}
.national-images{
    display: grid;
    flex-direction: column;
    gap: 0px;
    height: 180px ;
    width: 120px;
    text-align: center;
    font-weight: 600;
}
.national-img{
    height: 100px;
    width: auto;
}


.slider{
    background-color: var(--yellow);
    padding: 0 20px ;
    width: 50%;
    height: auto;
    margin:3rem 0rem 0rem 1rem;     
    height: auto;
    
}
.slide-track{
    
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    margin: 10px;
    align-items: center;
    padding: auto;
    gap: 50px;
    
    
    
  
    

    
}


@keyframes slide-track {
    0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-250px * 7));
      }
}




.con{
    font-size: 18px;
}
.aa{
    
  
    position: relative;
    padding: 20px;
    align-items: center;
    width: 86.5%;
    left: 7%;
    


 
  
}


.ss{
    
    font-size: 20px;
}
.concon{
    display: flex;
    background-color: var(--yellow);
    padding: 25px;
    font-size: 18px;
}

.work_with_us{
 
    margin-top: 100px;
}




.wwu{
    color: var(--red);
    text-align: center;
    font-size: var(--head2);
    
}

@media (max-width:600px){
    .sub-ind-sec1{
      
       
        width:23rem;
        margin:1rem 0;
        color: black;
        font-size: var(--content1);
        background-color: var(--yellow);
    
    }
    .industrycard-content{
        width:23rem;
    }
}


