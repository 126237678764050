    .head{
    position: relative;
    font-size: 40px;
    
  
}
.left{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--yellow);
    padding: 40px;
    margin: 10px 10px;
   
    width: 40%;
    /* left: 5%; */
   

}
.acalist{
    margin: 10px;
    font-size: 18px;
}

.left img{
    position: relative;
    display: flex;

    height: auto;
    width: 300px;
    margin:auto;
    
    
    
    border-radius: 20px;
    
}

.right{
    position: relative;
    display: flex;
    left: 0px;
    width: 100%;
    margin: 0;
}
.pc2{
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 18px;
   
}
.pc11{
    margin-left: auto;
    margin-right: auto;
}
.pc1-head{
    color:var(--red);
    position: relative;
    font-size: 30px;
    
   
}
.pc1-headmain{
    color:var(--red);
    position: relative;
    font-size: 40px;
    display: flex;
    justify-content: center;
    margin: 2rem;
   
   
}
.aca{
    position: relative;
    margin: auto;

}

.pc1{
    position:relative;
    
    font-size: 18px;
    width: 95%;
    margin: 10px 10px;
    
   
    
}

.pc1-head2{
    position: relative;
 
}

.main{
    position: relative;
    display: flex;
    width: 1300px;
    margin: auto;
    }


.right{
    position: relative;
    display: flex;
    gap: 2rem;
    flex-direction: column;
   
}
.fac-links{
    position: relative;
   display: flex;
   color: black;
   color: black;
   filter: brightness(.1);
   margin: 5px;
   gap: 10px;
}
.fac-links a i{
    color: black;
}

.homepage_div{
    position: relative;
    display: flex;
    font-size: 30px;
    gap: 9px;
    text-shadow: 10px;
    top: 10px;
    filter: grayscale(1);
 
   
}

.links{
    position: relative;
    display: flex;
    align-items: center;
    left: 80px;
    gap: 10px;
    margin: 25px;
    scale: 2;
    filter: grayscale(1);
}

@media (max-width:600px){
    .pc1-headmain{
        font-size: 35px;
        width: 100%;
        text-align: center;
        position: relative;
        left: -30px;
    }

    .main{
        display: flex;
        flex-direction: column;
        width: 23rem;
    }

    .left{
        width: 26rem;
        position: relative;
        left: -2rem;
        margin-bottom: 20px;
    }

    .pc1{
        width: 22rem;
        
        margin-bottom: 20px;
    }

    .right{


    }


    .tablepub{
        position: relative;
        left: -150px;

        text-wrap: balance;

        width: 20rem;
    }


    .fac-links{
        display: flex;
        
    }
}