p {
  margin: 0;
  padding: 0;
}

.faculty-main {
  display: flex;
  justify-content: center;

 
  
}
.faculty-mainx{
  display: flex;
  justify-content: center;
  scale: 110%;
}
.namex{
  font-weight: 900 !important;
  font-size: var(--content2);
  font-size: 24px;
  color: var(--red);
}
.faculty-main1 {
  display: flex;
  justify-content: center;
}

.faculty-width{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;
  row-gap: 3rem;
  padding: 4rem;
  font-size: var(--content1);
  max-width: 1400px;
}
.faculty-width1{
  display: flex;
  width: 1400px;
  justify-content: left;
  column-gap: 50px;
  row-gap: 3rem;
  padding: 4rem;
  max-width: 1400px;
}

.hodimg {
  height: 200px;
  width: 170px;
  border-radius: 10px;
}

.hodsec {
  display: flex;
  flex-direction: row;
  
  align-items: center;

  width: 500px;
  border-radius: 10px;
  box-shadow: 1px 1px 7px #000;
  padding: 10px;
}

.name {

  font-weight: 900 !important;
  font-size: var(--content2);
  font-size: 24px;
  color: var(--red);
}

.peoplecont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hodtext {
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  justify-content: left;
  /* align-items: center; */

  padding-left: 15px;
  padding-right: 15px;
}

.hodtext1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;


}
.faculty-width1{
  display: flex;
  position: relative;
  justify-content: center;

}
.Faculty{
  
  font-size: var(--head1);
  color: var(--red);
  text-align: center;
}

.hodtext2 {
  display: flex;
  text-align: justify;



  width: fit-content;
}

.faculty {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  height: 300px;
  background-color: white;
  width: 30%;
  box-shadow: 1px 1px 10px #000;
  padding: 10px;
}
.faculty-head-2{
  margin-top: 1rem;
  font-size: var(--head1);
  color: var(--red);
  text-align: center;
}
.post-button{
  text-align: center;
  width: fit-content;
  padding: 3px;
  border-radius: 5px;
  background-color: rgb(19, 83, 211);
  color: white;
}
.post-button a{
  text-align: center;
  padding: 3px;
  border-radius: 5px;
  background-color: rgb(19, 83, 211);
  color: white;
  text-decoration: none;
}

.staff {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  height: 300px;
  margin-left: auto;
  background-color: white;
  width: 30%;
  box-shadow: 1px 1px 10px #000;
  padding: 10px;
}

.students {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 300px;
  margin-left: auto;
  background-color: white;
  width: 30%;
  box-shadow: 1px 1px 10px #000;
  padding: 10px;
}

.view-button {
  border-radius: 10px;
  background-color: #1944C5;
  color: white;
  text-align: center;
  width: 100px;
  border: 0px;
}

.head{
  display: flex;
  margin-top: 3rem ;
  justify-content: center;
  text-align: center;
  width: auto;
  font-weight: bolder;
  color: red;
  font-size: 8rem;
  height: 15rem;
}

@media (max-width:600px) {
  .hodsec {
    height: 180px;
  }

  .hodimg {
    width: 30%;

  }

  .hodtext {
    font-size: 10px;
  }

  .peoplecont {
    flex-direction: column;
    gap: 20px;
  }

  .faculty {
    height: 150px;
    width: 70%;
  }
  .faculty-width{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 5px;
    row-gap: 2rem;
    padding: 0rem;
    max-width: 1400px;
    margin: 10px 0;
  }


  .staff {
    height: 150px;
    width: 70%;
    margin-left: 0;
  }

  .students {
    height: 150px;
    width: 70%;
    margin-left: 0;
  }
  
.head {
  display: flex;
  margin: 0;
  justify-content: space-evenly;
  width: auto;
  font-weight: bolder;
  color: red;
  font-size: 3rem;
  height: 6rem;
}
.faculty-head-2{
  font-size: 2rem;
}
.faculty-main {
  display: flex;
 
}
.hodsec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 220px;
  border-radius: 10px;
  box-shadow: 1px 1px 7px #000;
  padding: 10px;
}

.name {

  font-weight: bolder;
  font-size: 10px;
  color: red;
}
.hodimg {
  height: 140px;
  width: auto;
  /* width: 250px; */
  border-radius: 10px;
}
.hodtext {
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  justify-content: left;
  align-items: center;

  padding-left: 0px;
  padding-right: 0px;
}

.hodtext1 {
  display: flex;
  flex-direction: column;
  gap: 3px;


}
.post-button{
  text-align: center;
  padding: 3px;
  border-radius: 5px;
  background-color: rgb(100, 100, 247);
  color: white;
}
}