.resources-main{
    width: 100%;
    padding: 0 8rem;
    font-size: var(--content1);

}
.res-header{
    font-size: var(--content2);
    font-weight: 800;
    color: var(--red);
}
.resource-body{
    display: flex;
    flex-wrap: wrap;
    
    justify-content: center;
    align-items: center;
    
}
.res-acc-body a{
   text-decoration: none;
   font-size: var(--content1);
   color: white;
}
.res-body-content{
    display: flex;
    padding: 10px;
    gap: 30px;
}
.res-acc-body {
    margin: 10px 0;
    background-color: rgb(67, 91, 213);
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    gap: 10px;
   
}
.resource-block{
    display: flex;
    flex-direction: column;
 
    align-items: center;
    gap: 1rem;
    background: #f8e6cc;
    width: 300px;
    height: 300px;
    margin: 2rem;
    padding: 1rem;
    border-radius: 10px;
}
.resource-block ul{
    position: relative;
    left: -9%;
    list-style: none;
}
.resource-block ul li {
    font-size: var(--content1);
    line-height: 24px;
    font-weight: 600;
    
}
.head9{
    text-align: center;
    color: var(--red);
    display: flex;
    justify-content: center;
    font-size: var(--content2);
    font-weight: 700;
}
@media(max-width:600px){
    .resources-main{
        width: 100%;
        padding: 0 2rem;
        font-size: 10px;
    
    }
    .res-header{
        font-size: 15px;
    }
}
@media(max-width:447px){
    .resources-main{
        width: 100%;
        padding: 0 2rem;
        font-size: 10px;
    
    }
    .res-header{
        font-size: 15px;
    }
    .resource-block{
        display: flex;
        flex-direction: column;
     
        align-items: center;
        gap: 1rem;
        background: #f8e6cc;
        width: 330px;
        height: fit-content;
        margin: 2rem;
        padding: 1rem;

        border-radius: 10px;
    }
}