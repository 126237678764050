.gallery{
display: grid;
gap:    3rem;
padding: 30px;
grid-template-columns: repeat(4,1fr);
grid-gap: 20px;

}


.gallery .img{
    height: 250px;
    width: 340px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px rgb(172, 172, 172);
    cursor: pointer;
    transition: .3s;
}


.gallery .img:hover{
    opacity: 0.5;
}

.gallery .img:hover::after{
    content: "hello";

}

.pic{
    position: absolute ;
}


.magnify {
    position: relative    ;
    top: 0;
    left: 0;
    opacity: 0;
    width: 340px;
    height: 250px;
    color: black;
    font-size: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;
    
}

.magnify:hover{
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    opacity: 0.5;
    color: rgb(255, 255, 255);
}

.photo{
   
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 999; /* ensure it's above everything else */
  display: flex;
  justify-content: center;
  align-items: center;
   
}
.images{
    max-width: 679px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}



@media (max-width: 560px){
    .gallery{
        display: grid;
        position: relative;
        grid-template-columns: auto;
        justify-content: center;
        padding-right: 0 10px 10px 10px;

    }
    .img{
        max-width: 310px;
        max-height: 200px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px rgb(172, 172, 172);
        transition: .3s;
    }
    .gallery-img{
        align-items: center;
    }
    .magnify {
        position: relative    ;
        top: 0;
        left: 0;
        opacity: 0;
        max-width: 310px;
        max-height: 200px;
        color: black;
        font-size: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 11;
        
    }
    .images{
        width:auto;
        height: 200px;
    }
}

@media (min-width: 561px) and (max-width: 985px) {
    .gallery{
        display: grid;
        position: relative;
        grid-gap: 30px;
        grid-template-columns: auto auto;
        justify-content: center;
    }
    .img{
        max-width: 280px;
        max-height: 200px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px rgb(172, 172, 172);
        transition: .3s;
    }
    .gallery-img{
        align-items: center;
    }
    .magnify {
        position: relative    ;
        top: 0;
        left: 0;
        opacity: 0;
        max-width: 280px;
        max-height: 200px;
        color: black;
        font-size: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 11;
        
    }
}
@media (min-width: 985px) {
    .gallery{
        display: grid;
        position: relative;
        grid-template-columns:auto auto auto auto;
        justify-content: center;
    }
    .img{
        max-width: 280px;
        max-height: 200px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px rgb(172, 172, 172);
        transition: .3s;
    }
    .gallery-img{
        align-items: center;
    }
    .magnify {
        position: relative    ;
        top: 0;
        left: 0;
        opacity: 0;
        max-width: 280px;
        max-height: 200px;
        color: black;
        font-size: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 11;
        
    }
}

