.img-hgt img{

    height: 300px;
width: auto;
    border-radius: 10px;
   

}
.carousel-main{
    margin: auto;
    max-width: 1300px;
    height: 300px;
    /* box-shadow: 0px 0px 30px  #000; */
    border-radius: 10px;
    
    
}

.swiper-container {
    height: 350px;
    width: fit-content;
    margin: 30px;
  }
  
  
  .swiper-slide {
    padding: 10px;
    height: auto
    ;
    width: fit-content;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: white; */
    font-size: 20px;
    font-weight: bold;
  }
  
  .swiper-wrapper {
    -webkit-transition-timing-function:linear!important; 
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important; 
  }

  .resize{
   
    width: fit-content;
    height: 330px;
    position: relative;
    display: flex;
   
   
  }
@media (max-width:600px){

    .img-hgt{

        height: 100%;
        width: fit-content;
        padding: 20px;
        
        
        
    
    }
    .carousel-main{
        margin: auto;
        /* max-width: 1300px; */
        height:250px ;
        width: 100%;
        box-shadow: 0px 0px 0px 0 #000;
        border-radius: 0px;
        gap:10px;
        position: relative;
        top: -30px;
        
        
        
    }
}