.even{
    color: rgb(203, 18, 18);
    text-align: center;
    
}
.ind-head{
    position: relative;
    justify-content: left;
}
.bold{
    font-weight: bold;
    
}
.bold2{
    font-weight: bold;
    position: relative;
    display: flex;
    
    
}

.event-section{
    border-radius: 20px;
}

.res-acc-body {

    justify-content: center;
    position: relative;
}

.sub-eve-sec1{
    /* width: 100%; */
    padding: 15px;
    margin:2rem 2rem;
    color: black;
    font-size: var(--content1);
    background-color: var(--yellow);
}

.table{
    max-width: 100%;
    position: relative;
    
  }


@media (max-width: 400px){
    .event-section{
        border-radius: 20px;
        width: 100%;
        padding-left:20px;
        /* padding-right: 1px; */
    }

    .table{
        width: 100%;
        position: relative;
        display: inline-block;

        left: -10px;
      }
      .sub-eve-sec1{
        padding-left: 20px;
        color: black;
        position: relative;

        margin: 10px 15px 5px -5px;
        /* min-width: fit-content; */
        font-size: var(--content1);
        background-color: var(--yellow);
    }
}


@media (max-width:600px) {
    .sub-ind-sec1{
        position: relative;
        display: flex;
        flex-direction: column;
        
    }
}