.stfcontainer{
    display: flex;
    flex-direction: row;
    flex-grow: inherit;
    width: 100%;
    height:fit-content   ;
    border-radius: 10px;
    /* box-shadow: 1px 1px 7px #000; */
    max-width: 1300px;
   
    background-color: var(--lightbg);
  
}

h4{
    display: flex;
    margin-left: 5rem;
    padding-bottom: 1rem;
}

.Rstaff{
    margin-top: 5rem;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.stfcontainer1{
    display: flex;
    justify-content: space-around;
    
}

.stftext{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    padding-left: 10px;
    text-align: left;


}

.stfimage{
    display: flex;
    justify-content: center;
    align-items: center;
   
    padding:1rem;
    border-radius: 10px;
    filter: drop-shadow(0 0 10);
    height: 100%;
}
.stfimage img{
    height: 24rem;
    width: 20rem;

  
}


@media(max-width:600px){
    .faculty-width{
        display: flex;
        flex-direction: column;
    }

    .hodsec{
        width: 370px;
        height: 450px;
        margin-top: 25px;
        margin-bottom: 5px;
    }

    .hodsec img{
        height: 200px;
        width: 200px;
       
    }

    .post{
        position: relative;
        font-size: 20px;
        width: 350px;
        left: -40px;
    }
    .name{
        font-size: 25px;
        width: 300px;
        position: relative;
        left: -30px;
    }
}
