.displaybutton201{
    border: 2px solid var(--red);
    background: var(--red);
    color: white;
    width: fit-content;
   border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all .5s;  
}
.nodispbutton201:hover{
    background: var(--yellow);
    width: fit-content;
    padding: 10px;
    transition: all .5s;
    cursor: pointer;
    border-radius: 10px;
}
.nodispbutton202:hover{
    background: var(--yellow);
    width: fit-content;
    padding: 10px;
    transition: all .5s;
    cursor: pointer;
    border-radius: 10px;
}
.nodispbutton201{
    border: 2px solid var(--red);
    color: var(--red);
    cursor: pointer;
    padding: 10px;
    transition: all .5s;
    border-radius: 10px;
}
.nodispbutton202{
border: 2px solid var(--red);
    color: var(--red);
    cursor: pointer;
    padding: 10px;
    transition: all .5s;
    border-radius: 10px;
}
.displaybutton202{
    border: 2px solid var(--red);
    background:var(--red);
    color: white;
    width: fit-content;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;            
    transition: all .5s;
}
.industry-butt{
    margin-top: 1rem;
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.displaycontent101{
    display: block;
}
.nodiscontent101{
    display: none;
}
.industry-main{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
    
}
.industry-cards{
    background-color:var(--yellow);
    height: fit-content;
    width: 1300px;
    margin: auto;
}
.industry-cards2{
    background-color:white;
    height: fit-content;
    width: 1300px;
    margin: auto;
    
}

.industrycards-head{
    display: flex;
    padding: 10px;
    font-size: 28px;
    color: var(--red);
}

.industrycard-content{
    display: flex;
    
    font-size: var(--content1);
    margin-top: 0px;
    margin-bottom: 1rem;
    margin-right: 1rem;
    
}
.industrycard-content ul{
  width: 60%;
    
}
.industrycard-content img{
   width: 40%;
   margin: 0 1rem 1rem 1rem ;
   border-radius: 10px;
    
}
.industrycard-contentimg img{
    width: 40%;
    margin: 0 1rem 1rem 1rem ;
    border-radius: 10px;
     
 }
.slide-track img{
    filter: drop-shadow(0px 0px 5px black);

}
.stat{
    margin: auto;
    margin-left: 1rem;
    height: 300px;
    width: 400px;
}

.stat img{
    height: 250px;
    width: 350px;

}








@media (max-width:600px) {





    .industry-cards{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 400px;
        

    }

    .industrycard-content{
        display: flex;
        flex-direction: column;

        width: 600px;
    }
        .img{
            height: 500px;
        }
    
 
    .industry-butt{
        width: 50%;
        margin: 5px;
        height: 70px;
        gap: 10px;
        position: relative;
        left: 90px;
        font-size: 15px;
    }


    .slide-track{
        display: flex;
        position: relative;
        flex-direction: column;
        margin-top: 10px;
      
        left: -110px;

    }

    .industrycards-head{
        position: relative;
        text-align: center;
        justify-content: center;
    }


    .industry-cards2{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 400px;
        
       
    }


    .consultancy-logos{
        position: relative;
        display: flex;
        flex-direction: column;
        left: -125px;
    }
    

    .consult-button{
        position: relative;
        left: -125px;
        margin-bottom: 10px;
    }


    .stat{
        
        width: 350px;
        height: 300px;
    }
    

    .industrycard-contentimg img{
        position: relative;
        justify-content: center;
        left: 40px;
        height: 200px;
        width: 280px;

    }

    .industrycard-content img{
        position: relative;
        justify-content: center;
        left: 40px;
        height: 200px;
        width: 280px;

    }
    .slide-track img{
        position: relative;
        justify-content: center;
        left: 10px;
        height: 100px;
        width: 150px;
    }

    .consultancy-logos img{

        position: relative;
        justify-content: center;
        left: 10px;
        height: 80px;
        width: 200px;

    }
    .stat img{
        
        width: 300px;
        height: 200px;
        position: relative;
        left: -35px;
    }

    .industrycards-head{
        width: 200px;
        position: relative;
        justify-content: center;
    }
    
    
}