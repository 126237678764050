.career-accord{
    width: 1300px;
    margin: auto;

}
.foot-hgt-adjst{
    height: 10rem;
}
@media (max-width: 600px){
    .career-accord{
        max-width: 600px;
        width: 400px;
        margin: auto;
    
    }
    .foot-hgt-adjst{
        height: 10rem;
    }
}