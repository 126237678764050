/* #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  } */
  
  /* body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */
  
  
  .swiper {
    width: 100%;
    height: 100%;
    
  }
  
  .swiper-slide {
   
 
  
    text-align: justify;
    font-size: 18px;
    font-weight: 900;
   
    color: rgb(0, 0, 0);
    
  
    
    display: flex;
    justify-content: center;
    
  }
  .swiper-cont{
    display: flex;
    gap: 25px;
  }
  .swiper-cont img{
    height: 20px ;
    width: 200%;
  }
 
  
    /* .swiper-slide img {
    
    
      width: 20rem;
      height: 100%;
      object-fit: cover;
    }
    */
@media(max-width:600px){
  .swiper-cont.img{
    display: none;
  }
}