.swiper-cont img{
    height:250px ;
    width: 250%;
  }


  .imagesize{
    height: 200px ;
    width: 100%;
    
  }