@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nabla&display=swap");
.footer-body {
  position: sticky;
  top: 100%;
  margin-top: 60px;
  background-color: #005a9c;
  height: fit-content;
  font-size: 20px;
}
.footer-main {
  padding: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 10rem;
  justify-content: space-evenly;

  align-content: center;
  color: white;
}
.footer-head {
  font-size: 25px;
  font-weight: bold;
}
.foot-img {
  height: 30px;
  width: auto;
}
.footer-logos {
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  gap: 20px;
}
.footer-logos div a {
  display: flex;
  color: white;
  text-decoration: none;
  gap: 20px;
}
.footer-logos1 {
  display: flex;
  flex-direction: row;
  color: white;
  text-decoration: none;
  gap: 10px;
}
.footer-logos1 div a {
  color: white;
  font-size: 30px;
  text-decoration: none;
  gap: 20px;
}
.created a {
  color: aliceblue;
}
.created {
  position: relative;

  justify-content: center;
  font-size: 12px;
  text-decoration: none;
height: fit-content
;
  font-weight: 800;
  color: aliceblue;
  background-color: #005a9c;

  background-size: 100%;

  word-spacing: 4px;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 400%;
  }
  100% {
    background-position: 00%;
  }
}

@media (max-width: 600px){
    .footer-body {
        position: sticky;
        top: 100%;
        margin-top: 60px;
        background-color: #005a9c;
        height: fit-content;
        font-size: 20px;
      }
      .footer-main {
        padding: 60px;
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        justify-content: space-evenly;
      
        align-content: center;
        color: white;
      }
      .footer-head {
        font-size: 25px;
        font-weight: bold;
      }
      .foot-img {
        height: 30px;
        width: auto;
      }
      .footer-logos {
        display: flex;
        flex-direction: column;
        color: white;
        text-decoration: none;
        gap: 20px;
      }
      .footer-logos div a {
        display: flex;
        justify-content: flex-start;
        color: white;
        text-decoration: none;
        gap: 20px;
      }
      .footer-logos1 {
        display: flex;
        flex-direction: row;
        color: white;
        text-decoration: none;
        gap: 10px;
      }
      .footer-logos1 div a {
        color: white;
        font-size: 30px;
        text-decoration: none;
        gap: 20px;
      }
      .created a {
        color: aliceblue;
      }
      .created {
        position: relative;
      
        justify-content: center;
        font-size: 12px;
        text-decoration: none;
      height: fit-content
      ;
        font-weight: 800;
        color: aliceblue;
        background-color: #005a9c;
      
        background-size: 100%;
      
        word-spacing: 4px;
      }
      
}