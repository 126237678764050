.res-acc-body-button{
   
    display: flex;
    justify-content: center;
    position: relative;
    padding:10px 10px;
    
}
.box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image{

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    /* margin: 10px; */
    padding: 10px;
    gap: 50px;
    align-content: center;
}

.info img{
aspect-ratio: 1.2/1;
}

.info{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 400px;

}
.journeyhead{
    position: relative;
    text-align: center;
    margin: 15px;
    color: var(--red);
    font-weight: bold;
    font-size: 24px;
}
.direction{
    position: relative;
    font-size: 18px;
    margin-top: 15px;
}

@media screen and (max-width:500px){
    .info{
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 260px;
    
    }
}