/* .cards img{
 
  height: auto;
  width: 400px;
  border-radius: 10px;
  filter: brightness(0.6);
  z-index: 0;
  } */
.consulting{
  background-color: var(--yellow);
  align-items: center;
  width: 1300px;
  margin:auto;
  padding-left: 20px;
  padding-bottom: 20px;
 

}

.research-cards{
  display: flex;
  justify-content: space-evenly;
  
  




}
.research-cards a{
  text-decoration: none;
  
  




}


/* .table{
  width: 93%;
  position: relative;
 left: 50px;
  justify-content: center;
} */
.cards-1{

  background-image: url('./images/Alloy_and_metal_samples_-_Beryllium-Copper\,_Inconel\,_Steel\,_Titanium\,_Aluminum\,_Magnesium.jpg') ;
  
 
   background-size: 400px auto;

   width: 400px;
   height: 225px;
   border-radius: 10px;
   filter: brightness(1.5);
   
   z-index: 0;




}
.cards-2{

  background-image: url('./images/XHo7a.png') ;
  
 
   background-size: 400px auto;

   width: 400px;
   height: 225px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.cards-3{

  background-image: url('./images/steel-rusting.jpg') ;
  
 
   background-size: 400px auto;

   width: 400px;
   height: 225px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.cards-4{

  background-image: url('./images/The-environmental-impacts-of-solar-and-wind-energy-960x640.jpg') ;
  
 
   background-size: 400px auto;

   width: 400px;
   height: 225px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.cards-5{

  background-image: url('./images/10871331-macro-of-electronic-chip-1580037479.jpg') ;
  
 
   background-size: 400px auto;

   width: 400px;
   height: 225px;
   border-radius: 10px;
   filter: brightness(1.2);
   z-index: 0;




}
.cards-6{

  background-image: url('./images/maxresdefault.jpg') ;
  
    
   background-size: 400px auto;

   width: 400px;
   height: 225px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.research-card-main{
  margin-bottom: 2rem;
}
.research-card-line{
 display: flex;
 flex-direction: column;
 gap: 3rem;



}
.research-head{
  font-size: var(--head2);
  color: var(--red);
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.research-text{
  font-size: var(--content2);
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  
  text-decoration: none ;

  width: 400px;
  height: 225px;
  z-index: 2;
}


.consulting{
 display: flex;
 
}
.ss{
  display:flex;
  position: relative;
  flex-direction: column;
  gap: .51px;

}
.research-section{
  display: flex;
  width: 1300px;
  gap: 4rem;
  margin: auto;
}
.research-secleft{
display: flex;
flex-direction: column;
justify-content: center;
width: 50%;
}
.research-secright{
display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;
width: 50%;
}
.fund-plot{
  position: relative;
  width: 100%;
}
.fund-plot img{
  position: relative;
  width: 100%;
}
.consult-button{
  background-color: rgb(43, 82, 210);
  padding: 4px;
  border-radius: 5px;
  transition: all .3s;
  
}
.consult-button:hover{
  cursor: pointer;
  padding: 8px;
  background-color: rgb(43, 82, 210);
  border-radius: 5px;
  transition: all .3s;
  box-shadow: 0px 0px 5px black;
}
.consult-button a{
  text-decoration: none;
  color: white;
}
.consultancy-logos{
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}
.consultancy-logos img{
  height: 50px;
}


@media (max-width: 600px){
  .fund-plot{
    position: relative;
    width: 400px;
  }
  .fund-plot img{
    margin: auto;
    position: relative;
    width: 100%;
  }
  .consult-button{
    background-color: rgb(43, 82, 210);
    padding: 4px;
    border-radius: 5px;
    transition: all .3s;
    
  }
  .consult-button:hover{
    cursor: pointer;
    padding: 8px;
    background-color: rgb(43, 82, 210);
    border-radius: 5px;
    transition: all .3s;
    box-shadow: 0px 0px 5px black;
  }
  .consult-button a{
    text-decoration: none;
    color: white;
  }
  .consultancy-logos{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }
  .consultancy-logos img{
    height: 50px;
  }
  .research-section{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: fit-content;
    gap: 3rem;
    margin: auto;
  }
  .research-secleft{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  }
  .research-secright{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  }
  
.research-cards{
  display: flex;
  justify-content: space-evenly;
  
  




}
.research-cards a{
  text-decoration: none;
  
  




}


.table{
  width: 93%;
  position: relative;
 left: 50px;
  justify-content: center;
}
.cards-1{

  background-image: url('./images/Alloy_and_metal_samples_-_Beryllium-Copper\,_Inconel\,_Steel\,_Titanium\,_Aluminum\,_Magnesium.jpg') ;
  
 
   background-size: 100px auto;

   width: 100px;
   height: 100px;
   border-radius: 10px;
   filter: brightness(1.5);
   
   z-index: 0;




}
.cards-2{

  background-image: url('./images/XHo7a.png') ;
  
 
  background-size: 100px auto;

  width: 100px;
  height: 100px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.cards-3{

  background-image: url('./images/steel-rusting.jpg') ;
  
  background-size: 100px auto;

   width: 100px;
   height: 100px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.cards-4{

  background-image: url('./images/The-environmental-impacts-of-solar-and-wind-energy-960x640.jpg') ;
  
 
  background-size: 100px auto;

  width: 100px;
  height: 100px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.cards-5{

  background-image: url('./images/10871331-macro-of-electronic-chip-1580037479.jpg') ;
  
 
  background-size: 100px auto;

  width: 100px;
  height: 100px;
   border-radius: 10px;
   filter: brightness(1.2);
   z-index: 0;




}
.cards-6{

  background-image: url('./images/maxresdefault.jpg') ;
  
  background-size: 100px auto;

  width: 100px;
  height: 100px;
   border-radius: 10px;
   filter: brightness(1.5);
   z-index: 0;




}
.research-card-main{
  margin-bottom: 0rem;
}
.research-card-line{
 display: flex;
 flex-direction: column;
 gap: 3rem;



}
.research-head{
  font-size: var(--head2);
  color: var(--red);
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.research-text{
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  
  text-decoration: none ;

  width: 100px;
  height: 100px;
  z-index: 2;
}

}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
