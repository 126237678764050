:root {
    --lightbg: #f8e6cc;
    --white: #ffffff;
  }

.slider-main{
    margin: rem 0;
    position: relative;
    
    
}
.body{
    /* background:linear-gradient(#000c31,#1944C5); */

   background: white;
   z-index: -7;
  
  
}
.sec {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items:  center;
    justify-content: center;
   
    }

.sec-img img{
   
    height: auto;
    width:250px;
    border-radius: 10px;
}
.sec2{
    display: flex;
    max-width: 1300px;
    
    justify-content: center;
    padding: 40px;
    gap: 3rem;
    background-color:var(--yellow);
   margin: 0rem 6rem;
}
.sec-cont{
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    width: 1500px;
    font-size:18px;
    /* color: white; */
    
    
    text-align: justify;
}
.vision{
    display: flex;
    flex-direction: column;
    text-align: justify;
    align-items: center;
    padding: 0  3rem;
    background-color: #ffffff;
    margin: .5rem 7rem;
    /* border:  6rem   solid white; */
    /* color: white; */
    font-size:18px;
    max-width: 1300px;
    
}
.item-cor{
    height: 350px;
    background-color: white;
    
}

@media(max-width:600px){
   .sec-img img{
   
        height: auto;
    
        border-radius: 10px;
    } 
    .sec{

        display: flex;
       
        justify-content: center;
        align-items: center;
        margin: 0rem 0;
        padding: 0px;
        gap: 2rem;
        border: 0px;
        } 
        .sec2{
            display: flex;
            flex-direction: column;
            align-items: center;
            width:100%;
            max-width: 1300px;
            
            justify-content: center;
            padding: 30px;
            gap: 1rem;
            background-color: var(--yellow);
            margin: 0rem 0rem;
        }  
        .sec-cont{
          
        width: 100%;
            
        }
        .sec-conthead{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            /* color: white; */
            
            text-align: center;

        }
        
        .vision{
            display: flex;
            flex-direction: column;
            text-align: justify;
            align-items: center;
            padding: 20px;
            margin: 0rem;
            border: 0px;
            /* color: white; */
            
        }
        .item-cor{
            /* height: 200px; */
            background-color: white;
            
        }
        .slider-main{
            margin: 0;
            position: relative;
            
            
        }
}