.facilities-items{
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    /* left: 3%; */
    
    
}
.facilities-items img{
   width: 10rem;
   height: 10rem;
   width: 300px;
   height: 250px;
   align-items: center;
}
.facility-images{
    display: flex;
    position: relative;
    padding: 15px;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.facilities-head{
  position: relative;
  left: 450px;
  align-items: center;
}

.Instruments_In_Use{
  text-align: center;

}

@media (max-width: 988px){
  .facilities-items{
    justify-content: center;
    gap: 2rem;
  }
  .facilities-items img{
    max-width: 100%;
    height: auto;
    align-items: center;
 }
}
@media (min-width: 989px){
  .facilities-items{
    justify-content: center;
    gap: 2rem;
  }
  .facilities-items img{
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
}