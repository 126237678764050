.research-heads{
    display: flex;
   flex-direction: column;
   text-align: left;
  gap: 20px;
  border-radius: 10px;
 
   align-items: right;
   font-size:var(--content1);
   font-weight: 8   00;
 background-color:var(--yellow);
   height: 100%;
   width: 20%;
   height: auto;
  color: black;
   padding: 30px;
  

}
.research-heads div h2{
    font-size: var(--head1);
    font-weight: 600;
}


.heads-1{
    position: relative;
    /* background: rgba(58, 62, 138, 0.5); */
  background: var(--red);
  border-radius: 4px;
  padding: 5px;
    
    cursor: pointer;
    text-decoration: none;
    color: rgb(255, 255, 255);
     
}
.noheads-1{
    color: var(--red);

}
.noheads-1:hover{
    cursor: pointer;
    color: var(--red);
}

.indicator1{
    display: none;
    background: rgba(229, 230, 251, 0.8);

    z-index: index 1; ;
    position: absolute;
    left: -2rem}
.active-indicator{
        display: flex;
        flex-direction: row;
        background: rgba(216, 218, 241, 0.8);
        z-index: index 1; ;
        position: absolute;
        left: -2rem
    }
.research-main{
    display: flex;

    gap: 40px;
    padding: 0 40px;
    justify-content: space-evenly;
    max-width: 1500px;
  
}
.research-content{
    width: 40vw;
    text-align: justify;
    display: none;

}
.research-content-head{
    color: var(--red);
    font-size: var(--head1);
    margin: 2rem 0;
}
.research-info{
    padding: 10px;
}
.prof-names{
    border-radius: 10px;
    background-color:var(--yellow);
    display: flex;
    flex-direction: column;
    padding: 30px;
    font-size: var(--content1);
    width: 20%;
}
.research-body{
    display: flex;
    justify-content: center;
}

.no-profnames{
    
        border-radius: 10px;
        background-color: blanchedalmond;
        display: none;
        flex-direction: column;
        padding: 30px;
        width: 20%;
    
    
       
    }



.prof-names ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    list-style: none;
    padding: 0px;
 
}
.prof-names ul li a{
    color: black;
    text-decoration: none;

 
}


.active-content1{
    width: 40%;
    text-align: justify;
    justify-content: space-evenly;
    font-size: var(--content1);
  
}
.faculty-involved  h3{
    font-size: var(--head1);
    font-weight: 600;
    margin-bottom: 20px;
}


@media(max-width:600px)
{
    .research-main{
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 40px;
        justify-content: space-evenly;
      
    }
    .research-heads{
        display: flex;
       flex-direction: column;
      gap: 20px;
     
       align-items: right;
       font-size: 15px;
     background-color: rgba(58, 62, 138, 0.5);
       height: 0%;
       width: 100%;
       height: auto;
      
       padding: 30px;
      
    
    }
    .active-content1{
        width: 100%;
        text-align: justify;
        justify-content: space-evenly;
       
    }
    .prof-names{
        border-radius: 10px;
        background-color:var(--yellow);
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 100%;
    }
    
}
