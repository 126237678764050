.table-main{
    background-color: white;
   
}
.sec-3{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    padding: 0 120px;
}
.eve-pos{
    display: flex;
    flex-wrap: wrap ;
    justify-content: space-evenly;
    /* color: white; */
}
.events{
    display: flex;
    flex-direction: column;
    
    gap: 5rem;
    width: 400px;
    border-radius: 10px;
    box-shadow:  0px 0px 40px black;
}

.img
{
    display: flex;
    position: relative;
   border-radius: 25px;
    height: 100px;
    width: 20px;
}

.open-pos{
    display: flex;
    flex-direction: column;


    gap: 5rem;
    width: 400px;
}
.ranking-main{
    padding: 20px;
   width: 400px;
   height: 300px;

   color: black;
    line-height: 40px;
   font-size: var(--content2);
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgb(79, 77, 77);
}
.ranking-news-main{
    height: 300px;
   width: 850px;
   padding: 20px;
 

    
    box-shadow: 0px 0px 20px rgb(79, 77, 77);
    
    border-radius: 5px ;
}
.ranking-news-main ul{
 list-style: none ;
 color: black;
 display: flex;
 flex-direction: column;
 

}
    
.ranking{
    height: fit-content;
    width:  fit-content;
   
    border-radius: 10px;
    margin-top: 1rem;
    padding: px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

   

}
.gallery-grid img{
    
 height: 3rem;
  width: 100%;
  
}
.gallery-grid2{
    position: absolute;
    display: flex;
   
    height: fit-content;
    width: fit-content;
    
}
.gallery-grid7{
    position: absolute;
    display: flex;
   
    height: fit-content;
    width: fit-content;
   
    
    
}
.grid-itemxx{
    position: relative;
    border-radius: 10px;
    height: 300px;
    overflow: hidden;
    
    width: 400px;
    filter: brightness(0.6);
   
    
   
    
}
.grid-itemx{
    position: relative;
    border-radius: 10px;
    height: 300px;
    width: 400px;
    right: 0px;
    filter: brightness(.6);
    z-index: 1;
}
.grid-item1{
    opacity: .8;
}
.grid-item2{
    opacity: .4;
}
.grid-item3{
    opacity: .1;
}

.gallery-grid {
    max-height: 6rem;
  
    display: grid;
    grid-template-columns:   4rem 4rem  ;
    grid-template-rows:   3.5rem 3rem;
    

    

    
} 
.ranking3{
    display: flex;
  justify-content: center;
  width: 100%;
   
     
}

.sub-rank{
   display: flex;

   align-items: center;
gap: 2.5rem;
margin: 2rem;

   max-width: 1300px;  
    
     
}
.section4{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;

    
    


}
.subsubhead{
  font-size: 24px;
  color: var(--red);
  position: relative;
  
  display: flex;
  justify-content: left;
  font-weight: bold;
  left: -560px;
  margin: 5px;

}
.subsubhead2{
  font-size: 20px;
  font-weight: bold;
  margin: 5px;
  position: relative;
  display: block;
  justify-content: left;
  text-align: left;
  
 
  
}
.events1{
    height: 220px;
    width:  400px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgb(89, 88, 88);
    margin-top: 3rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;
}   
.events12{
    height: fit-content;
    width:  250px;
    
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgb(83, 83, 83);
    margin-top: 6rem;
    padding: 20px;
    justify-content: center;
}   
.event-button a{
    display: flex;
    justify-content:space-evenly;
   

    border-radius: 5px;
    text-decoration: none;
    color: white;
    background-color:#4b7bf5;
    width: 100px;
}
.event-button {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
  
    text-decoration: none;
    color: white;
   z-index: 2;
    
}
.stats-content2{
   width: 400px;

}
.stats-content2 img{
  
  height: 219px;



}
.eventx{
    color:rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 800;
    z-index: 2;
}
.heading{
  padding: 30px;

}
.sec-conthead{
  display: flex;
  justify-content: center;
    /*font-size: var(--head1);*/
    font-size: 40px;
    /*color: var(--red);*/
    color:rgb(203, 18, 18);
    margin-bottom:1rem;
    z-index: 2;
}
.sec-conthead_news{
    /*font-size: var(--head1);*/
    font-size: 40px;
    /*color: var(--red);*/
    color:rgb(255, 255, 255);
    margin-bottom: 10px;
    z-index: 2;
}


.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
   .studentcbutton{
    position: fixed;
    left: 95%;
    bottom: 5%;
    justify-content: center;

    z-index: 1000;

   }
   .button {
    position: relative;
    font-size: 14px;
    bottom: 0%;
    left: -25px;
    padding: 17px 10px;
    border-radius: 100px;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: #ffffff;
    border-color: #005a9c;
  color: #005a9c;
  border: 2px solid #005a9c;
    transition: all 0.2s ease;
  }
  
  
  .button:active {
    transform: scale(0.96);
  }
  .button:hover {
    background: #005a9c;
    color: white;
  }
  .button:before,
  .button:after {
    position: absolute;
    content: "";
    width: 150%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1000;
    background-repeat: no-repeat;
  }
  
  .button:hover:before {
    top: -70%;
    background-image: radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, #0b77c5 20%, transparent 30%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #0b77c5 15%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
      10% 10%, 18% 18%;
    background-position: 50% 120%;
    animation: greentopBubbles 0.6s ease;
  }
  
  @keyframes greentopBubbles {
    0% {
      background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
        40% 90%, 55% 90%, 70% 90%;
    }
  
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
        50% 50%, 65% 20%, 90% 30%;
    }
  
    100% {
      background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
        50% 40%, 65% 10%, 90% 20%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
  
  .button:hover::after {
    bottom: -70%;
    background-image: radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #0b77c5 15%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%),
      radial-gradient(circle, #0b77c5 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
    background-position: 50% 0%;
    animation: greenbottomBubbles 0.6s ease;
  }
  
  @keyframes greenbottomBubbles {
    0% {
      background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
        70% -10%, 70% 0%;
    }
  
    50% {
      background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
        105% 0%;
    }
  
    100% {
      background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
        110% 10%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
  












@media(max-width:600px){
  
  
  .sec2{
    position: relative;
    display: flex;
  
    
  }
  .name{
    display: flex;
    font-size: 15px;
  }
  .sec-img{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 70%;
   
    justify-content: center;
    left: -50px;
 
  }
  
  .hodtext1 {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    left:50px;
    

    
    
}



  .grid-itemx{
    position: relative;
    justify-content: center;
    width: 350px;
    height: 250px;
   
    
  }
  
    .sub-rank{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
     
     
         width: fit-content;  
         
          
     }
        
   
    .ranking-news-main{
       
       padding: 20px;
     max-width: 400px;
width: 95%;
        
        box-shadow: 0px 0px 20px rgb(79, 77, 77);
        
        border-radius: 5px ;
    }
   
    .eve-pos{
        display: flex;
        flex-wrap: wrap ;
        gap: 3rem;
        justify-content: space-evenly;
        /* color: white; */
        padding: 20px   ;
    }
    .events1{
        display: flex;
        flex-direction: column;
text-align: center;
        gap: 1rem;
    max-width: 400px;
        width: 95%;
        border-radius: 10px;
        box-shadow:  0px 10px 20px black;
    }
    .sec-conthead{
        
        text-align: center;

    }
    .section4{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      gap: 2.5rem;
  }
  

  .vision{
    width: 90%;
  }
  .vision_main_text{
    font-size: smaller;
  }
.sec-cont{
  width: 90%;
}
  .About_department_text{
    font-size: smaller;
  }
 .ranking2{
  position: relative;
  justify-content: center;
  left: -100px;
  width: 50%;
 }
 .studentcbutton{
  left: 85%;
  top: 90%;
 }

 
 
   
}
