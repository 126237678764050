.acadhead{
  display: flex;
  justify-content: center;
  font-size: 8rem;
  padding: rem;
  
  color: #C44536;
  

}
.heading{
  display: flex;
  justify-content: center;
  font-size: var(--head2);
  color: #C44536;
}
.acad-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 40rem;
  border: black;
}
.acad-image img{
  max-width: 1300px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  
  
}
.acad-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.acad-content{
  
  display: flex;
  flex-direction: column;
 font-size: var(--content1);
  width: 81rem;
  
}
.acad-head1{
  color: #C44536;
  font-size: var(--content2)
}
.statsimg{
  margin-top: 2rem;
  position: relative;
  display: flex;
  aspect-ratio: 1/1;
  height: 400px;
  justify-content: center;
  left: 300px;
}
@media(max-width:600px){
  .acad-body{
    display: flex;
  
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    position: relative;
    top: 60px;
  }
  .acad-content{
    margin: 2rem;
    display: flex;
    flex-direction: column;
   
    width: 23rem;
    
  }
  .acadhead{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    padding: 1rem;
    color: #C44536;
    
  
  }
  .acad-image {
    position: relative;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 6rem;
    border: black;
  }
  .acad-image img{
    width: 90%;
    height: 20rem;
    border-radius: 10px;
    
  }
}