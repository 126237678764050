.swiper-cont img{
    height: 250px ;
    width: 100%;
  }


  .imagesize{
    height: 100px ;
    
  }


@media (max-width:600px) {

 
.imagesize{
  height: 50px ;
  width: 50px;
  
}
  .stats-content2{

    position: relative;
    width: 50px;
    left: -100px;
    
    
  }

  
}